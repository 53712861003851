export const colors = {
    LandingPage_Front : '#F1F2F1',
    LandingPage_Background: '#E9E9E1',
    LandingPage_Description: '#373737',
    Button_Primary: '#f1501a',
    Button_Secondary: '#f1501a',
    Header_Background: "#FFFFFF",
    Header_Content: '#000000',
    Black: '#000000',
    Header: '#f1501a',
    Header_Text: "#FFFFFF",
    Header_Text_back: "#f1501a",
    Table_Header: '#BFCACD',
    Action_Button_Back: '#f1501a',
    ALERT_BUTTON_GOTO_COLOR: "#c6e2f6",
    ALERT_BUTTON_CLOSE_COLOR:  "#ea969d",
    HEADER: '#f1501a',
    BORDER_BACKGROUND: "#d6d6d6",
    CAR_BOX_EVEN:'#E9E9E1',
    CAR_BOX_ODD:'#E9E9E1',
}
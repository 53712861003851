import React, { useState, useEffect} from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import useStyles from '../styles/styles';
import { useTranslation } from "react-i18next";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {calcEst, showEst, optionsRequired} from '../common/sharedFunctions';
import InfoArea from "components/InfoArea/InfoArea.js";


import AlarmIcon from '@mui/icons-material/Alarm';
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SchoolIcon  from '@mui/icons-material/School';

const Section = (props) => {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t } = useTranslation();
  const cartypes = useSelector(state => state.cartypes);
  const [data, setData] = useState([]);
  const auth = useSelector(state => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars.sort((a, b) => a.pos - b.pos));
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  useEffect(()=>{
    if(auth.profile && auth.profile.uid){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }
  },[auth.profile]);

  const navigate = useNavigate();
  const role = props.role;
  const color=props.color;

  const Boxs = () => {
    return(
      <Box sx={{ flexGrow: 1, minHeight: data.length > 0 ? '480px' : '160px',marginTop:5,marginBottom:1}}>
        <Typography variant="h4" fontWeight={400} style={{ color: `#3C4858`, textAlign: 'center', paddingBottom: '10px'}}>{t('car_type')}</Typography>

        {data && data.length > 0 ?
          <Grid container className={classes.sectionGridContainer}>
            {data.map((item, key) => {
              return (
                <div style={{marginBottom: 55}} key={key}>
                  <Box
                    xs={12}
                    md={3.5}
                    height={300}
                    minHeight={300}
                    width={300}
                    minWidth={275}
                    className={classes.sectionGridItem}

                    style={{ backgroundColor: key%2 ===0 ? color.CAR_BOX_EVEN : color.CAR_BOX_ODD, textAlign:'center'}}
                  >
                    <img alt='Car' src={item.image} style={{ width: 150, height: 80 }} />
                    <Typography style={{ margin: 11, color: 'black', fontSize: 20 }}>{item.name}</Typography>
                    <Box style={{ backgroundColor: 'white', height: 215, borderRadius: 25, padding: 10}} boxShadow={3}>
                      {settings.swipe_symbol === false ?
                      <Typography style={{ marginTop: 8,fontWeight:'bold',fontSize:25 }}>{settings.symbol}{item.rate_per_unit_distance}/{settings.convert_to_mile ? t('mile') : t('km')}</Typography>
                      :
                      <Typography style={{ marginTop: 8,fontWeight:'bold',fontSize:25 }}>{item.rate_per_unit_distance}{settings.symbol}/{settings.convert_to_mile ? t('mile') : t('km')}</Typography>
                      }
                      <Typography style={{ marginTop: 5 }}>{t('min_fare')} : {item.min_fare}</Typography>
                      <Typography style={{ marginTop: 5 }}>{t('extra_info')} : {item.extra_info}</Typography>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: '#f1501a', color: 'white'}}
                        sx={{ width: '150px', fontSize: '16px', marginTop: 3 }}
                        onClick={(e) => { e.preventDefault();
                          if(loggedIn){
                          navigate(((role && role === 'driver' )|| (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings',
                          { state: { carData: item }} )
                          }else{
                            navigate('/login')
                          }
                        }}
                      >
                        { role === 'driver' || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin')) ? t('info'): t('book_now_button')}
                      </Button>
                    </Box>
                  </Box>
                </div>
              )
            })}
          </Grid>
        :
          <Typography variant="h3" fontWeight={400} style={{ textAlign: 'center', padding: 35, color: color.Header}}>{t('service_start_soon')}</Typography>
        }
      </Box>
    )
  }

  return (
    <div>
      <Boxs/>
      <Box sx={{ borderRadius: '6px', padding: 10, minHeight: '350px', backgroundColor: `rgb(241, 242, 241)` }}>
        <Typography variant="h4" fontWeight={400} style={{ color: `#3C4858`, textAlign: 'center', paddingBottom: '10px'}}>{t('product_section_heading')}</Typography>
        <Typography variant="h6" fontWeight={400} style={{ color: '#999', textAlign: 'center'}}>{t('product_section_para')}</Typography>

        <Grid container className={classes.sectionGridContainer}>
          <Box
              xs={12}
              md={3.5}
              minHeight={150}
              className={classes.sectionGridItem1}
          >
            <div style={{display:'flex'}}>
              <InfoArea
                  title={t('pruduct_section_heading_4')}
                  description={t('product_section_4')}
                  icon={ContactPhoneIcon}
                  iconColor="warning"
                  vertical
              />
            </div>
          </Box>
          <Box
              xs={12}
              md={3.5}
              minHeight={150}
              className={classes.sectionGridItem1}
          >
            <div style={{display:'flex'}}>
              <InfoArea
                  title={t('pruduct_section_heading_5')}
                  description={t('product_section_5')}
                  icon={FavoriteIcon}
                  iconColor="danger"
                  vertical
              />
            </div>
          </Box>
        </Grid>

        <Grid container className={classes.sectionGridContainer}>
          <Box
              xs={12}
              md={3.5}
              minHeight={150}
              className={classes.sectionGridItem1}
          >
            <div style={{display:'flex'}}>
              <InfoArea
                  title={t('pruduct_section_heading_1')}
                  description={t('product_section_1')}
                  icon={EmojiTransportationIcon}
                  iconColor="danger"
                  vertical
              />
            </div>
          </Box>
          <Box
              xs={12}
              md={3.5}
              minHeight={150}
              className={classes.sectionGridItem1}
          >
            <div style={{display:'flex'}}>
              <InfoArea
                  title={t('pruduct_section_heading_2')}
                  description={t('product_section_2')}
                  icon={AlarmIcon}
                  iconColor="gray"
                  vertical
              />
            </div>
          </Box>
        </Grid>
        <Grid container className={classes.sectionGridContainer}>
          <Box
              xs={12}
              md={3.5}
              minHeight={150}
              className={classes.sectionGridItem1}
          >
            <div style={{display:'flex'}}>
              <InfoArea
                  title={t('pruduct_section_heading_3')}
                  description={t('product_section_3')}
                  icon={VerifiedUser}
                  iconColor="success"
                  vertical
              />
            </div>
          </Box>
          <Box
              xs={12}
              md={3.5}
              minHeight={150}
              className={classes.sectionGridItem1}
          >
            <div style={{display:'flex'}}>
              <InfoArea
                  title={t('pruduct_section_heading_6')}
                  description={t('product_section_6')}
                  icon={SchoolIcon}
                  iconColor="info"
                  vertical
              />
            </div>
          </Box>
        </Grid>
      </Box>
    </div>
  );
};

export default Section;